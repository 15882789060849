<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <!--<a-col :md="8" :sm="24">-->
              <!--<a-form-item label="邮箱" prop="email">-->
                <!--<a-input v-model="queryParam.email" placeholder="请输入邮箱" allow-clear/>-->
              <!--</a-form-item>-->
            <!--</a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <!--<template v-if="advanced">-->
            <!---->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="性别" prop="sex">-->
                  <!--<a-select placeholder="请选择性别" v-model="queryParam.sex" style="width: 100%" allow-clear>-->
                    <!--<a-select-option v-for="(d, index) in dict.type.sys_user_sex" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
                  <!--</a-select>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="邀请码" prop="invitationCode">-->
                  <!--<a-input v-model="queryParam.invitationCode" placeholder="请输入邀请码" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="是否推送" prop="isNotice">-->
                  <!--<a-select placeholder="请选择是否推送" v-model="queryParam.isNotice" style="width: 100%" allow-clear>-->
                    <!--<a-select-option v-for="(d, index) in dict.type.sys_notice_status" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
                  <!--</a-select>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="用户状态" prop="status">-->
                  <!--<a-select placeholder="请选择用户状态" v-model="queryParam.status" style="width: 100%" allow-clear>-->
                    <!--<a-select-option v-for="(d, index) in dict.type.sys_enable_disable" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
                  <!--</a-select>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
            <!--</template>-->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <!--<a @click="toggleAdvanced" style="margin-left: 8px">-->
                  <!--{{ advanced ? '收起' : '展开' }}-->
                  <!--<a-icon :type="advanced ? 'up' : 'down'"/>-->
                <!--</a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="dashed" @click="$refs.importExcel.importExcelHandleOpen()"
                  v-hasPermi="['biz:user:import']">
          <a-icon type="import" />导入
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :sexOptions="dict.type.sys_user_sex"
        :isNoticeOptions="dict.type.sys_notice_status"
        :statusOptions="dict.type.sys_enable_disable"
        :vipTypeOptions="dict.type.member_type"
        @ok="getList"
      />
      <edit-mobile-form
        ref="editMobileForm"
        :sexOptions="dict.type.sys_user_sex"
        :isNoticeOptions="dict.type.sys_notice_status"
        :statusOptions="dict.type.sys_enable_disable"
        :vipTypeOptions="dict.type.member_type"
        @ok="getList"
      />

      <import-excel ref="importExcel" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="sex" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_user_sex']" :value="record.sex"/>
        </span>
        <span slot="vipType" slot-scope="text, record">
          <dict-tag :options="dict.type['member_type']" :value="record.vipType"/>
        </span>
        <span slot="isNotice" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_status']" :value="record.isNotice"/>
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>


        <span v-hasPermi="['biz:user:edit:isSummit']"  slot="isSummit" slot-scope="text, record">
              <a-popconfirm ok-text="是" cancel-text="否" @confirm="confirmHandleIsSummit(record)"
                            @cancel="cancelHandleIsSummit(record)">
                <span slot="title">确认{{ record.nickname }}用户<b>{{ record.isSummit ==  0 ? '需要' : '不需要' }}</b>参加峰会吗?</span>
                <a-switch checked-children="   是" un-checked-children="   否"   :checked="record.isSummit == 1 " />
              </a-popconfirm>
            </span>

        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['biz:user:edit:mobile']" />
          <a @click="$refs.editMobileForm.handleUpdate(record, undefined)" v-hasPermi="['biz:user:edit:mobile']">
            <a-icon type="edit" />修改手机号
          </a>

          <a-divider type="vertical" v-hasPermi="['driving-school:campus:remove']" />

            <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:user:edit:gx']">
            <a-icon type="edit" />修改邀请关系
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUser,listUser,changeIsSummit, delUser } from '@/api/biz/user'
import CreateForm from './modules/CreateForm'
import EditMobileForm from './modules/EditMobileForm'
import { tableMixin } from '@/store/table-mixin'
import ImportExcel from './modules/ImportExcel.vue'

export default {
  name: 'User',
  components: {
    ImportExcel,
    CreateForm,
    EditMobileForm
  },
  mixins: [tableMixin],
  dicts: ['sys_user_sex', 'sys_notice_status', 'sys_enable_disable','member_type'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        email: null,
        nickname: null,
        sex: null,
        invitationCode: null,
        isNotice: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '邮箱',
        //   dataIndex: 'email',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '会员类型',
          dataIndex: 'vipType',
          scopedSlots: { customRender: 'vipType' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '生日',
        //   dataIndex: 'birthday',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '邀请码',
          dataIndex: 'invitationCode',
          ellipsis: true,
          align: 'center'
        },

        // {
        //   title: '是否推送',
        //   dataIndex: 'isNotice',
        //   scopedSlots: { customRender: 'isNotice' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '用户状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },

        {
          title: '是否需要参加峰会',
          dataIndex: 'isSummit',
          scopedSlots: { customRender: 'isSummit' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    cancelHandleIsSummit(){},
    /* 用户参加峰会状态修改 status 0不需要 1需要*/
    confirmHandleIsSummit(row) {
      const oldIsSummit = row.isSummit
      const text = row.isSummit == 0 ? '需要' : '不需要'
      const isSummit = row.isSummit == 1 ? 0 : 1
      changeIsSummit(row.id, isSummit).then((res) => {
        if (res.data) {
          row.isSummit = isSummit
          this.$message.success(text + '成功', 3)
        }else{
          row.isSummit = oldIsSummit
          this.$message.error(`${text}失败, ${res.message}`, 3)
        }
      }).catch(() => {
        this.$message.error(text + '异常', 3)
      })
    },


    /** 查询用户信息列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        email: undefined,
        nickname: undefined,
        sex: undefined,
        invitationCode: undefined,
        isNotice: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/user/export', {
            ...that.queryParam
          }, `用户信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
